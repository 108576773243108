import React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import LinkedInIcon from "../assets/linked-in.svg";
import Twitter from "../assets/twitter.svg";
import Github from "../assets/github.svg";

import "./layout.css";

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1>
          <Link to={`/`}>{title}</Link>
        </h1>
      );
    } else {
      header = (
        <h3>
          <Link to={`/`}>{title}</Link>
        </h3>
      );
    }
    return (
      <>
        <Header>{header}</Header>
        <div
          style={{
            // marginLeft: `auto`,
            // marginRight: `auto`,
            // maxWidth: rhythm(24),
            // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            overflow: "hidden"
          }}
          className="content"
        >
          <main>{children}</main>
        </div>
        <footer className="footer">© {new Date().getFullYear()}</footer>
      </>
    );
  }
}

function Header({ children }) {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { social } = data.site.siteMetadata;
        return (
          <header className="header">
            {children}
            <div className="social-media-icons">
              <a
                href={`https://www.linkedin.com/in/${social.linkedIn}`}
                title="LinkedIn profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </a>
              <a
                href={`https://twitter.com/${social.twitter}`}
                title="Twitter profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Twitter />
              </a>
              <a
                href={`https://github.com/${social.github}`}
                title="GitHub profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github />
              </a>
            </div>
          </header>
        );
      }}
    />
  );
}

const bioQuery = graphql`
  query BioQuery {
    site {
      siteMetadata {
        social {
          twitter
          github
          linkedIn
        }
      }
    }
  }
`;

export default Layout;
