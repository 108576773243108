import Typography from "typography";
import twinPeaksTheme from "typography-theme-twin-peaks";

twinPeaksTheme.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`
    }
  };
};

let typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.45,
  headerFontFamily: [
    "Nunito",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif"
  ],
  bodyFontFamily: ["Nunito", "sans-serif"],
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => {
    return {
      a: {
        color: "#4F6E65",
        textDecoration: "none",
        fontWeight: 'bold'
      },
      h1: {
        color: "#38544c"
      },
      header: {
        display: "flex"
      },
      blockquote: {
        paddingLeft: "1rem"
      }
    };
  }
});

// typography = new Typography(twinPeaksTheme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
